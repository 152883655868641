import React, { FunctionComponent, useState } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, Text, Linking, Modal, View } from 'react-native'
import Colors from '../constants/Colors'
import Moment from 'moment';
import Para from './Para'

type EventButtonProps = {
  text: string
  eventId: string
  location: string
  fee: string
  synopsis: string
  startDate: Any
  onPress: (event: GestureResponderEvent) => void
}

const EventButton: FunctionComponent<EventButtonProps> = (props: EventButtonProps) => {
	const [hovered, setHovered] = useState(false)
	const [modalVisible, setModalVisible] = useState(false);
	const eventLink = 'https://listings.events360.co.uk?evid=' + props.event.id
	
	function handleHoveredIn() {
		setHovered(true);
	};

	function handleHoveredOut() {
		setHovered(false);
	};

  return (
  <View>
    <Pressable style={() => [styles.EventButton, hovered && styles.EventButtonHover]} onPress={() => setModalVisible(true)} onHoverIn={handleHoveredIn} onHoverOut={handleHoveredOut} >
      <Text style={styles.buttonText}>{props.event.name}</Text>
      <Text style={styles.dateText}>{Moment(props.event.eventStartDate).format("DD MMM yyyy")}</Text>
      <Text style={styles.locationText}>{props.event.location}</Text>
      <Text style={styles.feeText}>Fee: &pound;{props.event.fee}</Text>
      <Text style={styles.availabilityText}>Spaces available: {props.event.num_spaces}</Text>
      <Text style={styles.bookText}>Click for more information or to book</Text>
    </Pressable>
	<Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
        <View style={styles.centeredView}>
		  <View style={styles.modalView}>
			  <View style={styles.modalHeader}>
				<Pressable><Text style={styles.modalHeaderCloseText} onPress={() => setModalVisible(false)}>X</Text>
				</Pressable>
			  </View>

            <Text style={styles.modalText}>
				<Text style={styles.buttonText}>{props.event.name}</Text>
				<Para>{props.event.synopsis}</Para>
			</Text>
            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => Linking.openURL(`${eventLink}`)}>
              <Text style={styles.textStyle}>Book</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
	  </View>
	  )
}

const styles = StyleSheet.create({
  buttonText: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  dateText: {
    color: '#d1410c',
    fontSize: 14,
    textAlign: 'center',
  },
  locationText: {
    fontSize: 14,
    textAlign: 'center',
  },
  feeText: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
  },
  availabilityText: {
    color: '#cccccc',
    fontSize: 14,
    textAlign: 'center',
  },
  bookText: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
	paddingTop: 5,
  },
  EventButton: {
    borderColor: '#cccccc',
	backgroundColor: '#ffffff',
    padding: 10,
    marginBottom: 20,
	borderStyle: 'solid',
	borderWidth: 1,
  },
  EventButtonHover: {
    borderColor: '#bbbbbb',
	backgroundColor: '#dddddd',
    padding: 10,
    marginBottom: 20,
	borderStyle: 'solid',
	borderWidth: 1,
  },
  centeredView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
	width: '90%',
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 2,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'left',
  },
  modalHeaderContent: {
    flexGrow: 1,
  },
  modalHeaderCloseText: {
    textAlign: "right",
	fontSize: 16,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5
  }
  })

export default EventButton
