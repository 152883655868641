import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native'
import Checkbox from 'expo-checkbox';
import Para from './Para'

class LabeledCheckboxField extends Component {
  constructor(props) {
    super(props);
	
	this.state = {
      isChecked: this.props.value
    };

    this.toggleCheck = this.toggleCheck.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    var value = event;
    this.props.onChange(this.props.id, value);
  }
  
  toggleCheck() {
    this.setState({isChecked: !this.state.isChecked});
	this.props.onChange(this.props.id, this.state.isChecked);
  }

  render() {
    return (
      <View style={styles.LabeledCheckboxField}>
		<View onClick={this.toggleCheck}>
			<Para><Checkbox id={this.props.id} type="checkbox" style={styles.LabeledCheckboxFieldField} onValueChange={this.handleChange} value={this.props.value} checked={this.state.isChecked}/> {this.props.label}</Para>
		</View>
      

      </View>
    );
  }
}

export default LabeledCheckboxField;

const styles = StyleSheet.create({
  LabeledCheckboxField: {
    margin: 5,
	textAlign: 'left',
	paddingTop: 10
  },
  LabeledCheckboxFieldLabel: {
    fontSize: 20,
	marginBottom: 5,
	color: '#120e28'
  },
  LabeledCheckboxFieldField: {
    fontSize: 25,
	padding: 5,
	marginRight: 10
  }
})


