import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ScrollView, Text, Picker } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import ConditionalButton from '../components/ConditionalButton'
import ContentEditable from 'react-contenteditable'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import LabeledCheckboxField from '../components/LabeledCheckboxField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Table from '../components/Table'
import Api from '../components/Api'
import Moment from 'moment';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';


interface EventPaymentProps {
  attendee: Any
  onPaid: Function
  onSubmitPayment: Function
  onFail: Function
  clientSecret: string
}


export default function EventPayment ({ attendee, onPaid, onSubmitPayment, onFail, clientSecret  }: EventPaymentProps) {
	const [buttonText, setButtonText] = useState('Pay')
	const stripe = useStripe();
	const elements = useElements();

  function canContinue () {
    return buttonText === 'Pay'
  }
  
  async function ConfirmPayment (clientSecret, cardElement, confirmPaymentResult) {

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

		const confirmResult = await stripe.confirmCardPayment(clientSecret, {
			  payment_method: {
				card: cardElement
			  },
		});
		
					console.log(confirmResult);

		if (confirmResult.error) {
		  console.log('[error]', confirmResult.error.code);
		  confirmPaymentResult(false)
		} else {
			console.log(confirmResult);
		  confirmPaymentResult(true)
		}
	  
  }


const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
	setButtonText('Please Wait...');
	
	const cardElement = elements.getElement(CardElement);
	
	onSubmitPayment(onPaid, onFail, ConfirmPayment, cardElement);
  };

  return (
    <View style={styles.outerContainer}>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
          <Para>
            To complete your booking, please pay your fee of &pound;{attendee.fee}
          </Para>
		  <CardElement />
          <VerticalSpace height={15}/>
          <ConditionalButton onPress={handleSubmit} text={buttonText} condition={canContinue}/>
        <VerticalSpace height={15}/>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
