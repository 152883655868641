import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'

import ListEvents from './screens/ListEvents'
import EventBooking from './screens/EventBooking'
import EventPayment from './screens/EventPayment'
import EventPaymentSuccess from './screens/EventPaymentSuccess'
import EventPaymentFail from './screens/EventPaymentFail'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js'; // **** Needed for web
import { StripeProvider } from '@stripe/stripe-react-native';// **** Needed for apps
import Api from './components/Api'

const stripeKey = 'pk_test_wN1ctZrnd2MUHyeosqnbYchL00fb3kSGAE';
const stripePromise = loadStripe(stripeKey);

export default function App() {
	const [screenname, SetScreenName] = useState(null)
	const [account_number, SetAccountNumber] = useState('')
	const [event_id, SetEventId] = useState('')
	const [attendee, SetAttendee] = useState(null)

	useEffect(() => {
		if (screenname == null)
		{
			const query = new URLSearchParams(location.search);
			if (query.get('cid') != null)
			{
				SetAccountNumber(query.get('cid'));
				SetScreenName('list');
			}
			else if (query.get('evid') != null)
			{
				SetEventId(query.get('evid'));
				SetScreenName('evbooking');
			}
		}
	}, [screenname])
	
	const onBookingDone = (attendee) => {
		SetAttendee(attendee);
		SetScreenName('evpayment');
	}

	const handlePaymentMade = (clientSecret, ConfirmPayment, cardElement) => {
		console.log('payment done: ' + clientSecret);
		
		ConfirmPayment(clientSecret, cardElement, confirmPaymentResult)
	}
	
	const confirmPaymentResult = (paymentSuccess) => {
		if (paymentSuccess == true)
		{
			new Api().submitPaymentSuccess(attendee.id, event_id, function done(data) {
			SetScreenName('evpaymentsuccess');

			  },
			  function fail(data) {

			SetScreenName('evpaymentfail');
			  })	  
			
		}
		else
		{
			SetScreenName('evpaymentfail');
		}
	}

	const onSubmitPayment = (onPaid, onFail, ConfirmPayment, cardElement) => {
		new Api().stripePaymentIntent(attendee.id, function done(data) {
			handlePaymentMade(data, ConfirmPayment, cardElement)
      },
      function fail(data) {
		me.handlePaymentFailed()
		//setButtonText('Pay');
      })	  

  }

	const handlePaymentFailed = () => {
		console.log('payment failed');
	}
	
	const onRetryPayment = () => {
		SetScreenName('evpayment');
	}
	
  const screen = (): ReactElement => {
	  if (screenname == 'list') {
			return <ListEvents account_number={account_number}  />
	  }
	  else if (screenname == 'evbooking') {
			return <EventBooking event_id={event_id} onDone={onBookingDone}  />
	  }
	  else if (screenname == 'evpayment') {
		  return <Elements stripe={stripePromise}><EventPayment onPaid={handlePaymentMade} onSubmitPayment={onSubmitPayment} onFail={handlePaymentFailed} attendee={attendee} /></Elements>
	  }
	  else if (screenname == 'evpaymentsuccess') {
			return <EventPaymentSuccess />
	  }
	  else if (screenname == 'evpaymentfail') {
			return <EventPaymentFail onRetry={onRetryPayment} />
	  }
	  else
	  {
		  return (
			<View style={styles.container}>
			  <Text>There is a problem. Please contact our support team.</Text>
			  <StatusBar style="auto" />
			</View>
		  );
		  
	  }
  }	
	
  const Stack = createStackNavigator()
	
  return (
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            headerShown: false
          }}>
          <Stack.Screen name="Welcome" component={screen} />
        </Stack.Navigator>
      </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
