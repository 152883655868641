import React, { useState, useEffect, useRef } from 'react'
import { StyleSheet, View, ScrollView, Text, Picker } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import ConditionalButton from '../components/ConditionalButton'
import ContentEditable from 'react-contenteditable'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import LabeledCheckboxField from '../components/LabeledCheckboxField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Table from '../components/Table'
import Api from '../components/Api'
import Moment from 'moment';

interface EventBookingProps {
	event_id: Any,
	onDone: Function
}


export default function EventBooking ({ event_id, onDone }: EventBookingProps) {
	const [event, SetEvent] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [confirmemail, setConfirmEmail] = useState(null)
  const [terms, setTerms] = useState(false)
  const [submitting, setSubmitting] = useState(false)

 	useEffect(() => {
			new Api().GetEvent(event_id, onGetEventDone)
	}, [])
	
	function onGetEventDone(event) {
		SetEvent(event)
	}

  function canContinue() {
	  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	  return (firstName !== '' && lastName !== '' && email !== '' && terms == true && submitting == false && email == confirmemail && reg.test(email));
  }
  
  function updateFirstName(text: string) {
	  setFirstName(text)
  }

  function updateLastName(text: string) {
	  setLastName(text)
  }

  function updateEmail(text: string) {
	  setEmail(text)
  }

  function updateConfirmEmail(text: string) {
	  setConfirmEmail(text)
  }

  function updateTerms(string, value) {
	  setTerms(value)
  }

 function doContinue()
  {
	  setSubmitting(true)
	  new Api().AddAttendee(event.id, firstName, lastName, email, onAddAttendeeDone)
  }
  
  function onAddAttendeeDone(data)
  {
	  onDone(data);
  }
  
  return (
    <View style={styles.outerContainer}>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					{ event == null ? 
				<Para>Loading...</Para>
				:
				<View>
					<Text style={styles.buttonText}>{event.name}</Text>
					<Text style={styles.venueText}><b>Venue:</b> {event.venueName}, {event.venueAddress1}, {event.venueAddress3}, {event.venuePostcode} </Text>
					<Text style={styles.dateText}><b>Date:</b> {Moment(event.startDate).format("DD MMM yyyy")}</Text>
					<Text style={styles.feeText}><b>Fee:</b> &pound;{event.fee}</Text>
					<VerticalSpace height={20} />
					<LabeledTextField placeholder="Required" label="First name" onChange={updateFirstName} value={firstName}/>
					<LabeledTextField placeholder="Required" label="Last name" onChange={updateLastName} value={lastName}/>
					<LabeledTextField placeholder="Required" label="Email" onChange={updateEmail} value={email}/>
					<LabeledTextField placeholder="Required" label="Confirm email" onChange={updateConfirmEmail} value={confirmemail}/>
					<LabeledCheckboxField label="By using this form you agree with the storage and handling of your data by this website." onChange={updateTerms} value={terms}/>
					<VerticalSpace height={40} />
					<ConditionalButton onPress={doContinue} condition={canContinue} text="Submit"/>
					<VerticalSpace height={20} />
				</View>
					}
				<VerticalSpace height={20} />
			</View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  buttonText: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  },
  sectionHeader: {
	  fontWeight: 'bold'
  },  
})

