import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ScrollView, Text, Picker } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import ConditionalButton from '../components/ConditionalButton'
import ContentEditable from 'react-contenteditable'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import LabeledCheckboxField from '../components/LabeledCheckboxField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'


interface EventPaymentSuccessProps {
}


export default function EventPaymentSuccess () {


  return (
    <View style={styles.outerContainer}>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
          <Para>
            Thank you, you're payment was successful. We will send a confirmation email to the email address you provided.
          </Para>
        <VerticalSpace height={15}/>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
