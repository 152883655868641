import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ScrollView, Text, Picker } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import EventButton from '../components/EventButton'
import ContentEditable from 'react-contenteditable'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Table from '../components/Table'
import Api from '../components/Api'
import { confirmAlert } from 'react-confirm-alert'; // Import
import ReactDOM from "react-dom/client";

interface ListEventsProps {
	account_number: Any
}


export default function ListEvents ({ account_number }: ListEventsProps) {
	const [events, SetEvents] = useState(null)
	const [loading, SetLoading] = useState(true)

 	useEffect(() => {
			new Api().ListEvents(account_number, onListEventsDone)
	}, [])
	
	function onListEventsDone(events) {
		SetEvents(events)
		SetLoading(false)
	}


  return (
    <View style={styles.outerContainer}>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					{ loading ? 
				<Para>Loading...</Para>
				:
					events == null ? <Para>There is a problem. Please contact support</Para> : events.length == 0 ? <Para>There are currently no events available</Para> : events.map(event => (<EventButton key={event.id} event={event} />)) }
					
				<VerticalSpace height={20} />
			</View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
	backgroundColor: '#01a6ea',
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  },
  sectionHeader: {
	  fontWeight: 'bold'
  },  
})
