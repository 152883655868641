import { AlloyClaim, ApiPolicyRequest, ApiUserSessionRequest, CancellationRequest, GapClaim, Photo, SmartClaim, TyreClaim, WarrantyClaim } from "../ApiTypes"
import * as ImageManipulator from 'expo-image-manipulator';
import each from 'async/each';
import {decode as atob, encode as btoa} from 'base-64'
import { Platform } from 'react-native'

export default class Api {
  accessToken: string
  apiBase: string
  password: string
  sessionToken: string
  standardTimeout: number
  urlBase: string
  username: string
  jobid: string
  ClientSecret: string

  constructor () {
    this.urlBase = 'https://listings.events360.co.uk/api/'
    this.apiBase = this.urlBase + 'api/'
    this.username = ''
    this.password = ''
    this.standardTimeout = 30 * 1000

  }

  xWwwFormUrlEncoded (data: any): string {
    const components = []
    for (const property in data) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(data[property])
      components.push(encodedKey + '=' + encodedValue)
    }
    return components.join('&')
  }

  async ListEvents(account_id, onDone) {
    const api = this
    const url = this.apiBase +
      'events/list?account_id=' + encodeURIComponent(account_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
		onDone(null);
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async GetEvent(event_id, onDone) {
    const api = this
    const url = this.apiBase +
      'events/getevent?event_id=' + encodeURIComponent(event_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  onDone(null)
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async AddAttendee(event_id, first_name, last_name, email, onDone) {
    const api = this
    const url = this.apiBase +
      'attendee/create?first_name=' + encodeURIComponent(first_name) + 
		'&last_name=' + encodeURIComponent(last_name) + 
		'&email=' + encodeURIComponent(email) + 
		'&event_id=' + encodeURIComponent(event_id)
    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async stripePaymentIntent (attendee_id, onDone: Function, onFail: Function) {
    const api = this
    const url = this.apiBase +
      'payment/GetPaymentIntent?attendee_id=' + encodeURIComponent(attendee_id)
    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
		  onFail();
      } else {
		  const secret = await response.text()
		onDone(secret);
      }
    } catch (e) {
		  console.log(e)
		  onFail()
    }
	}
	
  async submitPaymentSuccess (attendee_id, event_id, onDone: Function, onFail: Function) {
    const api = this
    const url = this.apiBase +
      'attendee/paid?attendee_id=' + encodeURIComponent(attendee_id) +
		'&event_id=' + encodeURIComponent(event_id)
    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
		  onFail();
      } else {
		onDone();
      }
    } catch (e) {
		  console.log(e)
		  onFail()
    }
	}

  timeoutPromise (timeout: number): Promise<Response> {
    return new Promise<Response>((resolve, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
  }

  accessHeaders () {
    return {
      Authorization: 'Bearer ' + this.accessToken
    }
  }

}
